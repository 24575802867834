import React from "react"
import PropTypes from "prop-types"
import Poem from "../../../../components/poem"
import Notation from "../../../../components/notation"

const PoemFruehlingNotation = ({ size }) => {
  return (
    <Poem size={size}>
      <p>
        <strong>
          <Notation id="q-01">Der</Notation>{" "}
          <Notation id="q-02">Frühling</Notation>.
        </strong>
      </p>
      <br />
      <p>
        <Notation id="q-03">Wenn</Notation> <Notation id="q-04">aus</Notation>{" "}
        <Notation id="q-05">der</Notation> <Notation id="q-06">Tiefe</Notation>{" "}
        <Notation id="q-07">kommt</Notation> <Notation id="q-08">der</Notation>{" "}
        <Notation id="q-09">Frühling</Notation>{" "}
        <Notation id="q-10">in</Notation> <Notation id="q-11">das</Notation>{" "}
        <Notation id="q-12">Leben</Notation>,
      </p>
      <p>
        <Notation id="q-13">Es</Notation> <Notation id="q-14">wundert</Notation>{" "}
        <Notation id="q-15">sich</Notation> <Notation id="q-16">der</Notation>{" "}
        <Notation id="q-17">Mensch</Notation>,{" "}
        <Notation id="q-18">und</Notation> <Notation id="q-19">neue</Notation>{" "}
        <Notation id="q-20">Worte</Notation>{" "}
        <Notation id="q-21">streben</Notation>{" "}
      </p>
      <p>
        <Notation id="q-22">Aus</Notation>{" "}
        <Notation id="q-23">Geistigkeit</Notation>,{" "}
        <Notation id="q-24">die</Notation> <Notation id="q-25">Freude</Notation>{" "}
        <Notation id="q-26">kehret</Notation>{" "}
        <Notation id="q-27">wieder</Notation>{" "}
      </p>
      <p>
        <Notation id="q-28">Und</Notation>{" "}
        <Notation id="q-29">festlich</Notation>{" "}
        <Notation id="q-30">machen</Notation>{" "}
        <Notation id="q-31">sich</Notation>{" "}
        <Notation id="q-32">Gesang</Notation> <Notation id="q-33">und</Notation>{" "}
        <Notation id="q-34">Lieder</Notation>.
      </p>
      <br />
      <p>
        <Notation id="q-35">Das</Notation> <Notation id="q-36">Leben</Notation>{" "}
        <Notation id="q-37">findet</Notation>{" "}
        <Notation id="q-38">sich</Notation> <Notation id="q-39">aus</Notation>{" "}
        <Notation id="q-40">Harmonie</Notation>{" "}
        <Notation id="q-41">der</Notation> <Notation id="q-42">Zeiten</Notation>
        ,
      </p>
      <p>
        <Notation id="q-43">Daß</Notation>{" "}
        <Notation id="q-44">immerdar</Notation>{" "}
        <Notation id="q-45">den</Notation> <Notation id="q-46">Sinn</Notation>{" "}
        <Notation id="q-47">Natur</Notation> <Notation id="q-48">und</Notation>{" "}
        <Notation id="q-49">Geist</Notation>{" "}
        <Notation id="q-50">geleiten</Notation>,
      </p>
      <p>
        <Notation id="q-51">Und</Notation> <Notation id="q-52">die</Notation>{" "}
        <Notation id="q-53">Vollkommenheit</Notation>{" "}
        <Notation id="q-54">ist</Notation> <Notation id="q-55">Eines</Notation>{" "}
        <Notation id="q-56">in</Notation> <Notation id="q-57">dem</Notation>{" "}
        <Notation id="q-58">Geiste</Notation>,
      </p>
      <p>
        <Notation id="q-59">So</Notation> <Notation id="q-60">findet</Notation>{" "}
        <Notation id="q-61">vieles</Notation>{" "}
        <Notation id="q-62">sich,</Notation> <Notation id="q-63">und</Notation>{" "}
        <Notation id="q-64">aus</Notation> <Notation id="q-65">Natur</Notation>{" "}
        <Notation id="q-66">das</Notation> <Notation id="q-67">Meiste</Notation>
        .
      </p>
      <br />
      <p>
        <Notation id="q-68">d.</Notation> <Notation id="q-69">24</Notation>{" "}
        <Notation id="q-70">Mai</Notation> <Notation id="q-71">1758</Notation>.
      </p>
      <br />
      <p>
        <Notation id="q-72">Mit</Notation>{" "}
        <Notation id="q-73">Unterthänigkeit</Notation>
      </p>
      <p>
        <Notation id="q-74">Scardanelli</Notation>.
      </p>
    </Poem>
  )
}

PoemFruehlingNotation.propTypes = {
  size: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
}

PoemFruehlingNotation.defaultProps = {
  size: [2, 3],
}

export default PoemFruehlingNotation
